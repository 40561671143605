$bp-blue: #3AB8E8;
$bp-darkest-blue: #183368;
$bp-dark-blue: #234e89;
$bp-light-blue: #B8E9FD;
$bp-yellow: #FED367;
$bp-dark-yellow: #F0B52B;
$bp-red: #FF7E7F;
$bp-grey: #707070;
$bp-light-grey: #DDDDDD;
$bp-lightest-grey: #F8F8F8;
$bp-green: #2CC8AC;
$bp-text-color: #8E9CB9;

$white: #FFFFFF;
$black: #000000;

$page-font: "Open Sans", sans-serif;
$lato-font: 'Lato', sans-serif;

//Variables
$mobile-large: 576px;
$tablet-portrait: 768px;
$tablet-mid: 992px;
$tablet-landscape: 1024px;
$desktop: 1200px;
$desktop-lg: 1400px;
$desktop-xl: 1780px;

$input-height: 40px;
$header-height: 70px;
$logo-height: 30px;
$input-padding: 0 20px;
