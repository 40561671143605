.a-view.merchants {
  .all-merchants {
    .search-bar {
      max-width: 30%;
      margin: 20px 0;
    }
  }

  .create-new-merchant {
    margin-top: 20px;
    float: right;
  }

  .merchant-creation-container {
    margin-top: 40px;

    select option {
      background: #2d2d2d;
      color: #fff;
    }

    .field-error {
      color: orange;
    }

    input[type=checkbox] {
      /* Double-sized Checkboxes */
      -ms-transform: scale(2); /* IE */
      -moz-transform: scale(2); /* FF */
      -webkit-transform: scale(2); /* Safari and Chrome */
      -o-transform: scale(2); /* Opera */
      transform: scale(2);
      padding: 10px;
      float: left;
      width: inherit;
      margin-left: 0.5rem;
      margin-top: 0.5rem;
    }
  }

  .merchant-creation-form {
    margin-top: 40px;

    ::placeholder {
      color: gray;
      opacity: 1;
    }
  }

}

.a-view.merchant {
  .btitle {
    margin-bottom: 10px;
  }

  .merch-stat {
    display: block;
  }

  .merchant-details-form {
    .btn-link {
      color: $white;
    }
  }

  .search-bar {
    max-width: 30%;
    margin: 20px 0;
  }
}
