.post-order-feedback {

  .table {
    margin-bottom: 0;

    .response {
      background-color: transparent;

      th {
        text-align: right;
        border-top: none;
      }

      td, th {
        padding: 0.2rem 0.5rem;
        border-bottom: 1px solid rgba(193, 193, 193, 0.4);
      }

      tr th {
        text-align: right;
      }
    }

    .response-row {
      border: 1px solid #fff;
      padding: 20px;
    }

    td {
      border-top: none;
    }
  }

  .actions {
    display: flex;
    height: 100%;
    width: 100%;

    & > div {
      align-items: flex-end;
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    button {
      margin: 0 10px;
      cursor: pointer;
    }
  }

  .manual-sentiment {
    display: flex;
    width: 100%;

    & > div {
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }

    button {
      margin: 0 10px;
      cursor: pointer;
    }
  }
}