.btn {
  border: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  font-family: $page-font;
  min-width: 125px;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  outline: none!important;
  box-shadow: none!important;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &.btn-primary {
    background: $bp-blue;
    color: $white!important;
  }

  &.btn-secondary {
    background: $bp-dark-blue;
    color: $white;
  }

  &.btn-tertiary {
    background: $bp-dark-yellow;
    color: $white;
  }

  &.btn-quad {
    background: $bp-text-color;
    color: $white;
  }

  &.btn-link {
    color: $bp-dark-blue;
    font-size: 13px;

    &:hover {
      text-decoration: none;
    }
  }
}
