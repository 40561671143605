.header {
  height: $header-height;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, .1);
  padding: 20px 0;
  background: #393939;
  width: 100%;
}

.a-header {
  @extend .header;
  padding: 15px 30px 15px 15px;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 10;

  .logo {
    height: 50px;
    margin-top: -5px;
  }

  .profile-dropdown {

  }
}
