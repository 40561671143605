.bimage {
  max-width: 100%;
}

.btitle {
  display: block;
  color: $white;
  font-size: 38px;
  line-height: 48px;
  font-weight: bold;
  font-family: $lato-font;

  .dot {
    color: $bp-blue;
  }
}

.bp-card {
  background: #393939;
  border-radius: 6px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 60px 30px;
  width: 100%;
  color: $bp-text-color;
}

.react-toast-notifications__container {
  top: $header-height !important;
}

.page-builder {
  display: flex;
  overflow: hidden;
  position: relative;

  .content {
    padding: 25px 40px;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - #{$header-height});
  }
}

.sidebar {
  width: 60px;
  height: calc(100vh - #{$header-height});
  transition: width 0.5s;
  border-right: 1px solid rgba(#c1c1c1, 0.4);
  overflow: hidden;
  padding-top: 15px;
  display: flex;
  flex-direction: column;

  &:hover {
    width: 215px;
  }

  .sidebar-link {
    width: 215px;
    display: flex;
    padding: 10px 0;
    text-decoration: none;
    user-select: none;

    &:hover,
    &.active {
      background: rgba($bp-light-grey, 0.2);
    }

    &:last-child {
      border-top: 1px solid #e6e6e6;
      margin: auto 0 220px;
      padding: 15px 0;
    }

    &.disabled {
      pointer-events: none;

      span {
        color: $bp-light-grey;
        opacity: 0.5;
      }
    }

    img {
      width: 18px;
      display: block;
      margin: 0 22px 0 20px;
    }

    span {
      font-family: $lato-font;
      font-size: 13px;
      font-weight: bold;
      color: $bp-light-grey;
    }
  }
}

.bp-conv-searchbox {
  position: relative;

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    left: 20px;
    top: 10px;
    background-image: url('https://static.blueprint.store/illustrations/bp-search-icon-white.svg');
    position: absolute;
  }

  input {
    height: 40px;
    width: 100%;
    border-radius: 20px;
    border: 1px solid $bp-light-grey;
    padding: 0 0 0 60px;
    line-height: 40px;
    background: transparent;
    color: #e7e8eb;
  }
}

.merch-card {
  @extend .bp-card;
  padding: 20px;

  margin: 10px 0 20px;
  color: #e7e8eb;

  .merch-title {
    display: block;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.bp-table {
  padding: 0 15px;

  .t-header {
    > div {
      padding: 10px 15px;
      border-bottom: 1px solid #e7e8eb;
    }
  }

  .t-body {
    max-height: 500px;
    overflow-y: auto;
    margin: 0 -15px;
    padding: 0 15px;

    .line,
    .merchant-line {
      cursor: pointer;

      &:nth-of-type(odd) {
        background: rgba($bp-light-grey, 0.2);
      }

      > div {
        padding: 10px 15px;
        text-transform: capitalize;
      }
    }

    .line {
      > div {
        text-transform: none;
      }
    }

    .row {
      &.selected {
        background: $bp-dark-blue;
      }
      &:hover {
        background: $bp-darkest-blue;
      }
    }
  }
}

.bp-tabs {
  .bp-tabs-header {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 5px;

    .tab-item {
      flex: 1;
      text-align: center;
      padding: 20px 0;
      cursor: pointer;
      border-bottom: 5px solid transparent;
      transition: all 0.4s;

      &.active,
      &:hover {
        border-color: $bp-blue;
        border-radius: 20px 20px 0 0;
        background: rgba($bp-light-grey, 0.2);
      }
    }
  }

  .bp-tabs-body {
    .tab-body {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}

.bp-toggle {
  height: 20px;
  width: 40px;
  padding: 2px;
  border-radius: 20px;
  background: $bp-text-color;
  cursor: pointer;
  transition: all 0.5s;

  &.active {
    background: $bp-blue;

    .slider {
      margin-left: calc(100% - 16px);
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .slider {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: $white;
    transition: all 0.5s;
    margin-left: 0;
  }
}

.search-bar {
  min-width: 300px;
}

.orders-table {
  .t-body {
    .row {
      > div:last-child {
        padding: 10px 0 10px 10px;
      }
    }
  }
}

.phone-upload-result {
  div {
    margin-top: 30px;
  }

  p {
    font-size: 14px;

    &:not(:first-child) {
      margin-bottom: 5px;
    }
  }
}