html,
body {
  overflow-x: hidden;
  background: #2d2d2d;
  color: #e7e8eb;
}

.form-control {
  background: transparent;
  border-color: #e7e8eb;
  color: #e7e8eb;
  border-radius: 4px;

  &:focus,
  &:active {
    background: transparent;
    outline: none;
    box-shadow: none;
    color: #e7e8eb;
  }

  &::placeholder {
    color: #949494;
    font-size: 14px;
  }
}

.wl-input-group {
  position: relative;
  margin: 10px 0;

  .input-err-message {
    position: absolute;
    color: $bp-red;
    line-height: $input-height;
    font-size: 14px;
    font-style: italic;
    font-family: $lato-font;
    top: 0;
    right: 15px;
  }

  .form-control {
    height: $input-height;
    padding: 12px 15px;
    font-size: 14px;
    font-family: $lato-font;

    &.error {
      border-color: $bp-red;
    }
  }
}

.radio-flex {
  display: flex;
}

.form-error {
  color: $bp-red;
  font-size: 14px;
}
