.a-view.login {
  .login-card {
    @extend .bp-card;

    padding: 40px;
    max-width: 530px;
    margin: 30px auto;
    text-align: center;

    .login-title {
      display: flex;
      justify-content: center;
      align-items: baseline;
      color: $white;
      margin-bottom: 50px;

      .admin {
        margin-left: 5px;
        font-size: 24px;
      }
    }

    .form-container {
      max-width: 340px;
      margin: 0 auto;


    }

    .btn {
      margin: 20px auto;
      display: block;
    }
  }
}
